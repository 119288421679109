// Parcel's conversion of fs.readFileSync to a static string only works on .js files
import fs from "fs";

// Parcel will statically read this and embed it as a string
const runtime = fs.readFileSync(
  "./node_modules/kythera/build/src/runtime/runtime.js",
  "utf8"
);

export default runtime;
