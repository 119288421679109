import React, {
  useState,
  useReducer,
  Reducer,
  MouseEventHandler,
  useEffect,
} from "react";
import ReactDOM from "react-dom";

import CodeMirror from "@uiw/react-codemirror";
import { EditorView } from "@codemirror/view";

import compileKythera from "kythera";

import runtime from "./runtime";

import { match } from "ts-pattern";

import { inspect } from "util";

import { js as beautify } from "js-beautify";

import {
  FiPlay,
  FiTool,
  FiMinusCircle,
  FiAlertCircle,
  FiCheckCircle,
} from "react-icons/fi";

import "normalize.css";
import "@fontsource/source-code-pro";
import "@fontsource/source-sans-pro";
import "@fontsource/source-serif-pro";
import "./index.scss";

const DEFAULT_PROGRAM: string = `const fibo = fn(n) {
  if n <= 1 {
      return n
  }

  return fibo(n - 1) + fibo(n - 2)
}

let x = 1
while x <= 12 {
  console.log(fibo(x))
  x = x + 1
}`;

const theme = EditorView.theme({
  "&": {
    fontSize: "17px",
  },
  "&.cm-editor.cm-focused": {
    outline: "none",
  },
  ".cm-line": {
    fontFamily: "Source Code Pro",
  },
});

const prelude: string = `
const u_console = k_val({
  log: k_attachFnType(pushLog, u_Fn(u_None, u_None)),
});
`;

const App = () => {
  const [body, setBody] = useState<string>(DEFAULT_PROGRAM);
  type LogAction = "msg" | "reset";
  const [log, dispatchLog] = useReducer(
    (log = [], { action, msg }: { action: LogAction; msg?: any }) => {
      switch (action) {
        case "msg":
          return [...log, msg];
        case "reset":
          return [];
      }
    },
    []
  );
  // called by Kythera runtime
  const pushLog = (msg) => dispatchLog({ action: "msg", msg });

  const [compileError, setCompileError] = useState<Error | null>(null);
  const [runError, setRunError] = useState<Error | null>(null);

  const [compiledOutput, setCompiledOutput] = useState<string>("");

  const run = () => {
    const compiled = compile();

    if (!compiled) {
      return;
    }
    // evaluate code in its own scope
    (() => {
      try {
        eval(`${runtime}\n${prelude}\n${compiled}`);
      } catch (err) {
        console.log(err);
        setRunError(err);
      }
    })();
  };

  const compile = () => {
    setCompileError(null);
    setRunError(null);

    dispatchLog({ action: "reset" });

    setCompiledOutput("");

    try {
      return compileKythera(body);
    } catch (err) {
      console.log(err.message);
      setCompileError(err);
      return false;
    }
  };

  const handleCompile = (e?) => {
    if (e) {
      e.preventDefault();
    }
    const compiled = compile();
    setCompiledOutput(beautify(compiled));
  };

  const handleRun = (e?) => {
    if (e) {
      e.preventDefault();
    }
    run();
  };

  useEffect(() => {
    handleRun();
  }, []);

  return (
    <div>
      <div id="playground">
        <div id="editor">
          <div id="editor-buttons">
            <a className="button" href="#" onClick={handleRun}>
              <span>Run</span>
              <FiPlay />
            </a>
            <a
              className="editor-button button"
              href="#"
              onClick={handleCompile}
            >
              <span>Build</span>
              <FiTool />
            </a>
          </div>
          <CodeMirror
            autoFocus={true}
            extensions={[theme]}
            value={DEFAULT_PROGRAM}
            onChange={(value, viewUpdate) => {
              setBody(value);
            }}
          />
        </div>
        <div id="console">
          <div id="console-status">
            {compileError ? (
              <div id="console-status-compile" className="console-status-error">
                <span>
                  Build Error <FiAlertCircle />
                </span>
              </div>
            ) : (
              <div id="console-status-compile" className="console-status-ok">
                <span>
                  Build Ok <FiCheckCircle />
                </span>
              </div>
            )}
            {runError ? (
              <div id="console-status-compile" className="console-status-error">
                <span>
                  Run Error <FiAlertCircle />
                </span>
              </div>
            ) : compiledOutput || compileError ? (
              <div id="console-status-compile">
                <span>
                  Did not run <FiMinusCircle />
                </span>
              </div>
            ) : (
              <div id="console-status-compile" className="console-status-ok">
                <span>
                  Run Ok <FiCheckCircle />
                </span>
              </div>
            )}
          </div>
          {(() => {
            if (compiledOutput) {
              return (
                <div id="console-compiled">
                  <h1>Compiled output:</h1>
                  <pre>{compiledOutput}</pre>
                </div>
              );
            } else if (compileError) {
              return (
                <div className="console-error">{compileError.message}</div>
              );
            } else if (runError) {
              return <div className="console-error">{runError.message}</div>;
            } else {
              return log.map((entry, i) => (
                <div className="console-log-entry" key={i}>
                  {match(typeof entry)
                    .with("string", () => entry)
                    .with("object", () => inspect(entry))
                    .otherwise(() => entry.toString())}
                </div>
              ));
            }
          })()}
        </div>
      </div>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
